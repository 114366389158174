import Lenis from "lenis";
import gsap from "gsap";
import SplitType from "split-type";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { isTouchPointer, matchMedia } from "./utils";

import {
  introTextBlockFade,
  fadingTextLines,
  animateLogosIn,
  animateLogosOut,
  servicesAnimation,
  contactAnimation,
} from "./animations";

gsap.registerPlugin(ScrollTrigger);
gsap.config({ force3D: true });

document.addEventListener("DOMContentLoaded", () => {
  const lenis = new Lenis({
    duration: 0.75,
  });

  lenis.on("scroll", ScrollTrigger.update);

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  const isTouchDevice = isTouchPointer();

  /* Animations */

  const fadingTextBlock = document.querySelector("[data-fading-text-block]");
  const introLogo = document.querySelector("[data-main-logo]");
  introTextBlockFade(fadingTextBlock, introLogo);

  const introFadingTextLines = document.querySelectorAll("[data-fading-text] .line");
  fadingTextLines(introFadingTextLines);

  const logoMarquee = document.querySelector("[data-logos-container]");
  const logos = document.querySelector("[data-logos-container] .logos");
  const logoStrips = logos.querySelectorAll("[data-logos-marquee]");
  animateLogosIn(logoMarquee, logos, logoStrips, matchMedia);

  const introSection = document.querySelector(".section-intro");
  animateLogosOut(introSection, logoMarquee, logos);

  const servicesSection = document.querySelector(".section-services");
  const servicesContainer = document.querySelector(".section-services [data-services-container]");
  const services = document.querySelectorAll(".section-services [data-service]");

  setTimeout(() => {
    servicesAnimation(servicesSection, servicesContainer, services, isTouchDevice, matchMedia);
  }, 500);

  const contactSection = document.querySelector(".contact__inner");
  const contactScrollingText = contactSection.querySelector("[data-animate-text]");
  const hiddenContactInfo = contactSection.querySelector("[data-contact-info]");
  const splitText = new SplitType(contactScrollingText, { types: "lines, words, chars" });

  setTimeout(() => {
    // The TimeOut is a workaround for a Safari bug
    // inside we use element.getBoundingClientRect() which initially returns wrong values in Safari
    // this seems to be the case for Safari & iOS Safari :(
    contactAnimation(contactSection, splitText.chars, hiddenContactInfo);
  }, 500);
});
